<template>
  <div class="contents" id="contents">
    <section class="section half-section">
      <div class="section-wrap">
        <div class="left-area">
          <div class="text-wrap">
            <h2 class="title ani-text-up">Our Partners</h2>
            <div class="left-content-1 ani-text-up" v-html="$t('content.main.op.txt01')">
            </div>
            <div class="left-content-2 ani-text-up" v-html="$t('content.main.op.txt02')">
            </div>
          </div>
        </div>
        <div class="right-area partners">
          <div class="partners-wrap">
            <!-- Tab -->
            <BaseTab
              v-model="tabMainValue"
              v-bind:list="tabMainList"
              tabtype="type2"
              @input="tabMainCallback"
            >
              <template v-slot="Scope">
                <li class :class="Scope.paramClass">{{Scope.title}}</li>
              </template>
            </BaseTab>
            <!-- //Tab -->
            <!-- Our Partners List -->
            <div class="tab-contents-wrap">
              <!--  -->
              <div class="tab-contents">
                <div class="partners-container domestic">
                  <div class="partners-header-area">
                    <BaseTab
                      v-model="tabSubValue"
                      v-bind:list="tabSubList[tabMainValue]"
                      tabtype="small-tab"
                      @input="tabSubCallback"
                    >
                      <template v-slot="Scope">
                        <li class :class="Scope.paramClass">{{Scope.title}}</li>
                      </template>
                    </BaseTab>
                  </div>
                  <div class="partners-list-wrap">
                    <div class="partners-list">
                      <!-- Swiper -->
                      <StaggerTransition targets=".card-wrap">
                        <GroupedSlider
                          ref="swiperSlider"
                          :list="SwiperList[tabMainValue]"
                          :pagination="false"
                          :autoplay="false"
                          :loop="false"
                          :itemsPerGroup="12"
                          @change="onChange"
                        >
                          <template v-slot="{ item }">
                            <div class="card-wrap">
                              <div class="card" :class="{ 'hide': item.img ===''} ">
                                <img v-if="item.img" :src="requireAssetsImage(item.img)" :alt="item.title" />
                                <span :class="item.img ? 'blind': 'card-logo-text'">{{item.title}}</span>
                              </div>
                            </div>
                          </template>
                        </GroupedSlider>
                      </StaggerTransition>
                      <!-- // Swiper -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- //  -->
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mixin as mixinHelperUtils, mixinMotion } from '@/common/helperUtils';
import GroupedSlider from '@/components/slider/GroupedSlider.vue';
import StaggerTransition from '@/components/transition/StaggerTransition.vue';
import BaseTab from '@/components/base/BaseTab.vue';

export default {
  name: 'WhoWeAre',
  mixins: [mixinHelperUtils, mixinMotion],
  components: {
    GroupedSlider,
    BaseTab,
    StaggerTransition,
  },
  data() {
    return {
      tabMainValue: 0,
      tabSubValue: 0,
      tabMainList: ['Domestic', 'Global'],
      tabSubList: [
        ['Corporates', 'Govt. partners', 'VC / Accelerators', 'Academic'],
        ['PE', 'VC / Accelerators', 'ETC'],
      ],
      SwiperList: [
        [
          // Corporates
          {
            img: 'partners/domestic/corporates/corporates-01.png',
            title: 'Amazon AWS',
          },
          {
            img: 'partners/domestic/corporates/corporates-02.png',
            title: 'E-Land',
          },
          {
            img: 'partners/domestic/corporates/corporates-03.png',
            title: 'SK 주식회사 C&C',
          },
          {
            img: 'partners/domestic/corporates/corporates-04.png',
            title: '신패스 DHN',
          },
          {
            img: 'partners/domestic/corporates/corporates-05.png',
            title: '교원',
          },
          {
            img: 'partners/domestic/corporates/corporates-06.png',
            title: '현대',
          },
          {
            img: 'partners/domestic/corporates/corporates-07.png',
            title: '오라클',
          },
          {
            img: 'partners/domestic/corporates/corporates-08.png',
            title: '하나금융티아이',
          },
          {
            img: 'partners/domestic/corporates/corporates-09.png',
            title: 'FLOW',
          },
          {
            img: 'partners/domestic/corporates/corporates-10.png',
            title: 'NHN ENTERTAINMENT',
          },
          {
            img: 'partners/domestic/corporates/corporates-11.png',
            title: '잇츠한불',
          },
          {
            img: 'partners/domestic/corporates/corporates-12.png',
            title: '㈜장진우',
          },
          {
            img: 'partners/domestic/corporates/corporates-13.png',
            title: 'GRAP',
          },
          {
            img: 'partners/domestic/corporates/corporates-14.png',
            title: '토스',
          },
          {
            img: 'partners/domestic/corporates/corporates-15.png',
            title: '온아시아',
          },
          {
            img: 'partners/domestic/corporates/corporates-16.png',
            title: '하이보이스',
          },
          {
            img: 'partners/domestic/corporates/corporates-17.png',
            title: 'Jober',
          },
          {
            img: 'partners/domestic/corporates/corporates-18.png',
            title: 'TAGby',
          },
          {
            img: 'partners/domestic/corporates/corporates-19.png',
            title: '두잇컴퍼니',
          },
          {
            img: 'partners/domestic/corporates/corporates-20.png',
            title: '스티비',
          },
          {
            img: 'partners/domestic/corporates/corporates-21.png',
            title: '디라이트',
          },
          {
            img: 'partners/domestic/corporates/corporates-22.png',
            title: '삼정KPMG',
          },
          {
            img: 'partners/domestic/corporates/corporates-23.png',
            title: '모두투어',
          },
          {
            img: 'partners/domestic/corporates/corporates-24.png',
            title: 'JANDI',
          },
          {
            img: 'partners/domestic/corporates/corporates-25.png',
            title: '바이엘',
          },
          {
            img: 'partners/domestic/corporates/corporates-26.png',
            title: '예스24',
          },
          {
            img: 'partners/domestic/corporates/corporates-27.png',
            title: '고려대의료원',
          },
          {
            img: 'partners/domestic/corporates/corporates-28.png',
            title: '소니',
          },
          {
            img: 'partners/domestic/corporates/corporates-29.png',
            title: '플래디',
          },
          { img: '', title: '' },
          { img: '', title: '' },
          { img: '', title: '' },
          { img: '', title: '' },
          { img: '', title: '' },
          { img: '', title: '' },
          { img: '', title: '' },
          // Coperates

          // Govt
          {
            img: 'partners/domestic/govt/govt-01.png',
            title: '중소기업진흥공단',
          },
          {
            img: 'partners/domestic/govt/govt-02.png',
            title: '한국방송광고진흥공사',
          },
          {
            img: 'partners/domestic/govt/govt-03.png',
            title: '한국무역협회',
          },
          {
            img: 'partners/domestic/govt/govt-04.png',
            title: '창업진흥원',
          },
          {
            img: 'partners/domestic/govt/govt-05.png',
            title: '판교창업존',
          },
          {
            img: 'partners/domestic/govt/govt-06.png',
            title: '판교창업존',
          },
          {
            img: 'partners/domestic/govt/govt-07.png',
            title: '충남창조경제',
          },
          { img: '', title: '' },
          { img: '', title: '' },
          { img: '', title: '' },
          { img: '', title: '' },
          { img: '', title: '' },
          // Govt

          // Accelorators
          {
            img: 'partners/domestic/accelorators/accelorators-01.png',
            title: 'Maru 180',
          },
          {
            img: 'partners/domestic/accelorators/accelorators-02.png',
            title: 'The Ventures',
          },
          {
            img: 'partners/domestic/accelorators/accelorators-03.png',
            title: 'Ventire Square',
          },
          {
            img: 'partners/domestic/accelorators/accelorators-04.png',
            title: '롯데엑셀러레이터',
          },
          {
            img: 'partners/domestic/accelorators/accelorators-05.png',
            title: '원익투자파트너스',
          },
          {
            img: 'partners/domestic/accelorators/accelorators-06.png',
            title: '글로벌브레인',
          },
          {
            img: 'partners/domestic/accelorators/accelorators-07.png',
            title: '서울투자파트너스',
          },
          {
            img: 'partners/domestic/accelorators/accelorators-08.png',
            title: 'N15',
          },
          {
            img: 'partners/domestic/accelorators/accelorators-09.png',
            title: 'HGI',
          },
          {
            img: 'partners/domestic/accelorators/accelorators-10.png',
            title: 'Primer',
          },
          {
            img: 'partners/domestic/accelorators/accelorators-11.png',
            title: '디캠프(은행권청년창업재단)',
          },
          {
            img: 'partners/domestic/accelorators/accelorators-12.png',
            title: '1QLAB',
          },
          {
            img: 'partners/domestic/accelorators/accelorators-13.png',
            title: '신한 FUTURES LAB',
          },
          {
            img: 'partners/domestic/accelorators/accelorators-14.png',
            title: 'FINOLAB',
          },
          {
            img: 'partners/domestic/accelorators/accelorators-15.png',
            title: '위비핀테크 LAB',
          },
          {
            img: 'partners/domestic/accelorators/accelorators-16.png',
            title: '핀테크지원센터',
          },
          {
            img: 'partners/domestic/accelorators/accelorators-17.png',
            title: '하이보이스',
          },
          {
            img: 'partners/domestic/accelorators/accelorators-18.png',
            title: '법무법인 세움',
          },
          {
            img: 'partners/domestic/accelorators/accelorators-19.png',
            title: 'BLT특허사무소',
          },
          {
            img: 'partners/domestic/accelorators/accelorators-20.png',
            title: '무일국제특허법률사무소',
          },
          {
            img: 'partners/domestic/accelorators/accelorators-21.png',
            title: '퓨처플레이',
          },
          {
            img: 'partners/domestic/accelorators/accelorators-22.png',
            title: '코오롱 액셀러레이터',
          },
          {
            img: 'partners/domestic/accelorators/accelorators-23.png',
            title: '코오롱 액셀러레이터',
          },
          {
            img: 'partners/domestic/accelorators/accelorators-24.png',
            title: '매쉬업엔젤스',
          },
          // Accelorators

          // Academic
          {
            img: 'partners/domestic/academic/academic-01.png',
            title: '서울대학교 기술지주회사',
          },
          {
            img: 'partners/domestic/academic/academic-02.png',
            title: '서강 비즈니스 센터',
          },
          {
            img: 'partners/domestic/academic/academic-03.png',
            title: '성균관대학교 산학협력단',
          },
          {
            img: 'partners/domestic/academic/academic-04.png',
            title: '동국대학교 창업지원단',
          },
          {
            img: 'partners/domestic/academic/academic-05.png',
            title: '단국대학교 산학협력단',
          },
          {
            img: 'partners/domestic/academic/academic-06.png',
            title: '순천향대학교 창업보육센터',
          },
          {
            img: 'partners/domestic/academic/academic-07.png',
            title: '충남대학교 산학협력단',
          },
          {
            img: 'partners/domestic/academic/academic-08.png',
            title: '이화여자대학교',
          },
          {
            img: 'partners/domestic/academic/academic-09.png',
            title: '한양대학교 산학협력단',
          },
          // Academic

        ],
        [
          // PE
          {
            img: 'partners/global/pe/pe-01.png',
            title: 'Almack Mezzanine',
          },
          {
            img: 'partners/global/pe/pe-02.png',
            title: 'Alpinvest',
          },
          {
            img: 'partners/global/pe/pe-03.png',
            title: 'Ares',
          },
          {
            img: 'partners/global/pe/pe-04.png',
            title: 'Blackstone',
          },
          {
            img: 'partners/global/pe/pe-05.png',
            title: 'Carlyle',
          },
          {
            img: 'partners/global/pe/pe-06.png',
            title: 'CD&R',
          },
          {
            img: 'partners/global/pe/pe-07.png',
            title: 'Coller Capital',
          },
          {
            img: 'partners/global/pe/pe-08.png',
            title: 'Crestview Partners',
          },
          {
            img: 'partners/global/pe/pe-09.png',
            title: 'Goldman Sachs',
          },
          {
            img: 'partners/global/pe/pe-10.png',
            title: 'IFC',
          },
          {
            img: 'partners/global/pe/pe-11.png',
            title: 'KKR',
          },
          {
            img: 'partners/global/pe/pe-12.png',
            title: 'New Mountain Capital',
          },
          {
            img: 'partners/global/pe/pe-13.png',
            title: 'PAI',
          },
          {
            img: 'partners/global/pe/pe-14.png',
            title: 'Providence',
          },
          {
            img: 'partners/global/pe/pe-15.png',
            title: 'Vietnam Investment Group',
          },
          { img: '', title: '' },
          { img: '', title: '' },
          { img: '', title: '' },
          { img: '', title: '' },
          { img: '', title: '' },
          { img: '', title: '' },
          { img: '', title: '' },
          { img: '', title: '' },
          { img: '', title: '' },
          // PE

          // VC / Accelerators
          {
            img: 'partners/global/vc/vc-01.png',
            title: 'Anthemis',
          },
          {
            img: 'partners/global/vc/vc-02.png',
            title: 'Fintech Collective',
          },
          {
            img: 'partners/global/vc/vc-03.png',
            title: 'Golden Gate Ventures',
          },
          {
            img: 'partners/global/vc/vc-04.png',
            title: 'Pitango Venture Capital',
          },
          {
            img: 'partners/global/vc/vc-05.png',
            title: 'Vertex Ventures',
          },
          {
            img: 'partners/global/vc/vc-06.png',
            title: 'Wavemaker Partners',
          },
          { img: '', title: '' },
          { img: '', title: '' },
          { img: '', title: '' },
          { img: '', title: '' },
          { img: '', title: '' },
          { img: '', title: '' },
          // VC / Accelerators

          // Etc
          {
            img: 'partners/global/etc/etc-01.png',
            title: 'Finolab',
          },
          {
            img: 'partners/global/etc/etc-02.png',
            title: 'ONL',
          },
          {
            img: 'partners/global/etc/etc-03.png',
            title: 'Rakuten',
          },
          {
            img: 'partners/global/etc/etc-04.png',
            title: 'China Accelerator',
          },
          {
            img: 'partners/global/etc/etc-05.png',
            title: 'INNOSPACE',
          },
          {
            img: 'partners/global/etc/etc-06.png',
            title: 'Egg',
          },
          {
            img: 'partners/global/etc/etc-07.png',
            title: 'VIISA',
          },
          {
            img: 'partners/global/etc/etc-08.png',
            title: 'MAD INCUBATO',
          },
          {
            img: 'partners/global/etc/etc-09.png',
            title: 'AIR ASIA',
          },
          {
            img: 'partners/global/etc/etc-10.png',
            title: 'Pinehurst Advisors',
          },
          {
            img: 'partners/global/etc/etc-11.png',
            title: 'Fatfish Internet Group',
          },
          {
            img: 'partners/global/etc/etc-12.png',
            title: 'IdesSpace',
          },
          {
            img: 'partners/global/etc/etc-13.png',
            title: 'M8VC',
          },
          {
            img: 'partners/global/etc/etc-14.png',
            title: 'Ideasource',
          },
          {
            img: 'partners/global/etc/etc-15.png',
            title: 'GSF',
          },
          // Etc
        ],
      ],
    };
  },
  methods: {
    onChange(v) {
      if (this.tabMainValue === 0) {
        if (v <= 2) {
          this.tabSubValue = 0;
        } else if (v <= 3) {
          this.tabSubValue = 1;
        } else if (v <= 5) {
          this.tabSubValue = 2;
        } else {
          this.tabSubValue = 3;
        }
      } else if (v <= 1) {
        this.tabSubValue = 0;
      } else if (v <= 2) {
        this.tabSubValue = 1;
      } else {
        this.tabSubValue = 2;
      }
    },
    tabMainCallback() {
      this.tabSubValue = 0;
      this.$refs.swiperSlider.$el.swiper.slideTo(0);
    },
    tabSubCallback() {
      let tabValue = 0;
      if (this.tabMainValue === 0) {
        if (this.tabSubValue === 0) {
          tabValue = 0;
        } else if (this.tabSubValue === 1) {
          tabValue = 3;
        } else if (this.tabSubValue === 2) {
          tabValue = 4;
        } else {
          tabValue = 6;
        }
      } else if (this.tabSubValue === 0) {
        tabValue = 0;
      } else if (this.tabSubValue === 1) {
        tabValue = 2;
      } else {
        tabValue = 3;
      }
      this.$refs.swiperSlider.$el.swiper.slideTo(tabValue);
    },
  },
};
</script>

<style>
.partners-list .swiper-container {position:static;}
</style>
